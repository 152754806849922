import React, {useState, useEffect, useRef} from 'react';

export function Broker() {

    const [timeInBusiness, setTimeInBusiness] = useState('');
    const [grossAnnualSales, setGrossAnnualSales] = useState('');
    const [amountRequested, setAmountRequested] = useState('');
    const [industry, setIndustry] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [registeredLegalName, setRegisteredLegalName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [ownerFirstname, setOwnerFirstname] = useState('');
    const [ownerLastname, setOwnerLastname] = useState('');
    const [businessAddress, setBusinessAddress] = useState(null);
    const [businessZip, setBusinessZip] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [businessAddressIsHome, setBusinessAddressIsHome] = useState(true);
    const [homeAddress, setHomeAddress] = useState(null);
    const [homeZip, setHomeZip] = useState(null);
    const [homeCity, setHomeCity] = useState(null);
    const [homeState, setHomeState] = useState(null);
    const [acceptsCreditCards, setAcceptsCreditCards] = useState(false);
    const [avgMonthlySales, setAvgMonthlySales] = useState('');
    const [lowestMonthlySales, setLowestMonthlySales] = useState('');
    const [email, setEmail] = useState('');
    const [brokerCompanyId, setBrokerCompanyId] = useState('');
    const [isInternal, setIsInternal] = useState(false);
    const [brokerDictionary, setBrokerDictionary] = useState({});
    const [brokerAgentEmail, setBrokerAgentEmail] = useState('');
    const [brokerAgentFirst, setBrokerAgentFirst] = useState('');
    const [brokerAgentLast, setBrokerAgentLast] = useState('');
    const [brokerAgentPhone, setBrokerAgentPhone] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [submitDOB, setSubmitDOB] = useState('');
    const [percentOwnership, setPercentOwnership] = useState('');
    const [ssn, setSsn] = useState('');
    const [ein, setEin] = useState('');
    const [legalStructure, setLegalStructure] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [dealLink, setDealLink] = useState('');
    const [dealId, setDealId] = useState('');
    const [referenceId, setReferenceId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const brokerInfoFetchedRef = useRef(false);
    const [industries, setIndustries] = useState([]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const apiKey = params.get('k');
    const brokerId = params.get('cid');
    const baseApiUrl = process.env.REACT_APP_APPLY_API_URL

    const styles = {
        formContainer: {
            width: '400px',
            margin: '0 auto',
        },
        formGroup: {
            marginBottom: '20px',
        },
        label: {
            display: 'block',
            fontWeight: 'bold',
            marginBottom: '5px',
        },
        input: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        select: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
            appearance: 'none', // Remove default select arrow
            backgroundImage: `url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='12' height='12' fill='%23333'><path d='M0 6 L32 6 L16 28 z' /></svg>")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 8px center',
            backgroundSize: '12px',
        },
        option: {
            fontSize: '16px',
            backgroundColor: '#fff',
            color: '#333',
        },
        currencyInputContainer: {
            position: 'relative',
        },
        percentInputContainer: {
            position: 'relative',
            display: 'inline-block',
        },
        currencySymbol: {
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            fontSize: '16px',
            color: '#333',
        },
        currencyInput: {
            width: '100%',
            padding: '10px',
            paddingLeft: '30px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
        },
        percentInput: {
            width: '100%',
            padding: '10px',
            paddingLeft: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
        },
        suffix: {
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            fontSize: '16px',
            color: '#666',
        },
        errorMsg: {
          color: 'red'
        }
    };

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        // Trim leading white space
        if (typeof value === 'string') {
            value = value.toString().replace(/^\s+/g, '');
        }
        if (name === 'brokerAgentFirst') {
            setBrokerAgentFirst(value);
        } else if(name === 'brokerAgentLast') {
            setBrokerAgentLast(value);
        } else if(name === 'brokerAgentEmail') {
            setBrokerAgentEmail(value);
        } else if(name === 'brokerAgentPhone') {
            setBrokerAgentPhone(value);
        } else if(name === 'brokerCompanyId') {
            setBrokerCompanyId(value);
        } else if(name === 'timeInBusiness') {
            setTimeInBusiness(value);
        } else if(name === 'grossAnnualSales') {
            setGrossAnnualSales(value);
        } else if(name === 'amountRequested') {
            setAmountRequested(value);
        } else if(name === 'industry') {
            setIndustry(value);
        } else if(name === 'businessName') {
            setBusinessName(value);
        } else if(name === 'registeredLegalName') {
            setRegisteredLegalName(value);
        } else if(name === 'businessPhone') {
            setBusinessPhone(value);
        } else if(name === 'mobilePhone') {
            setMobilePhone(value);
        } else if(name === 'ownerFirstname') {
            setOwnerFirstname(value);
        } else if(name === 'ownerLastname') {
            setOwnerLastname(value);
        } else if(name === 'businessAddress') {
            setBusinessAddress(value);
        } else if(name === 'businessZip') {
            setBusinessZip(value);
        } else if(name === 'city') {
            setCity(value);
        } else if(name === 'state') {
            setState(value);
        } else if(name === 'businessAddressIsHome') {
            setBusinessAddressIsHome(value === 'yes');
        } else if(name === 'homeAddress') {
            setHomeAddress(value);
        } else if(name === 'homeZip') {
            setHomeZip(value);
        } else if(name === 'homeCity') {
            setHomeCity(value);
        } else if(name === 'homeState') {
            setHomeState(value);
        } else if(name === 'acceptsCreditCards') {
            setAcceptsCreditCards(value === 'yes');
        } else if(name === 'avgMonthlySales') {
            setAvgMonthlySales(value);
        } else if(name === 'lowestMonthlySales') {
            setLowestMonthlySales(value);
        } else if(name === 'email') {
            setEmail(value);
        } else if(name === 'dateOfBirth') {
            let ownerDOB = new Date(value);
            let month = ownerDOB.getMonth() + 1;
            ownerDOB = ownerDOB.getFullYear() + "-" + month + "-" + ownerDOB.getDate();
            setDateOfBirth(value);
            setSubmitDOB(ownerDOB);
        } else if(name === 'percentOwnership') {
            setPercentOwnership(value);
        } else if(name === 'ssn') {
            setSsn(value.toString().replace(/\D/g, ''));
        } else if(name === 'ein') {
            setEin(value.toString().replace(/\D/g, ''));
        } else if(name === 'legalStructure') {
            setLegalStructure(value);
        } else if(name === 'referenceId') {
            setReferenceId(value);
        }
    }

    function handleSubmit(event) {
        setIsSubmitting(true);
        event.preventDefault();
        let data = {
            brokerId: brokerCompanyId,
            brokerAgentFirst: brokerAgentFirst,
            bokerAgentLast: brokerAgentLast,
            brokerAgentEmail: brokerAgentEmail,
            brokerAgentPhone: brokerAgentPhone,
            timeInBusiness: timeInBusiness,
            grossAnnualSales: grossAnnualSales,
            amountRequested: amountRequested,
            industry: industry,
            businessName: businessName,
            registeredLegalName: registeredLegalName,
            businessPhone: businessPhone,
            mobilePhone: mobilePhone,
            ownerFirstname: ownerFirstname,
            ownerLastname: ownerLastname,
            businessAddress: businessAddress,
            businessZip: businessZip,
            city: city,
            state: state,
            businessAddressIsHome: businessAddressIsHome,
            homeAddress: homeAddress,
            homeZip: homeZip,
            homeCity: homeCity,
            homeState: homeState,
            acceptsCreditCards: acceptsCreditCards,
            avgMonthlySales: avgMonthlySales,
            lowestMonthlySales: lowestMonthlySales,
            email: email,
            ownerDOB: submitDOB,
            percentOwnership: percentOwnership,
            ssn: ssn,
            ein: ein,
            legalStructure: legalStructure,
            activationMethod: 'inbound',
            referenceId: referenceId,
        }
        fetch(baseApiUrl + '/v1/broker/submit-internal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                setDealLink('https://app.hubspot.com/contacts/' + process.env.REACT_APP_HUBSPOT_ACCOUNT_NUMBER + '/deal/' + response.data.dealId);
                setDealId(response.data.dealId);
            } else if(response.message) {
                setErrorMessage(response.message);
                window.scrollTo(0, 0);
            }
        })
        .catch(() => {
            window.scrollTo(0, 0);
            setErrorMessage('something went wrong while submitting application.');
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    }

    let legalStructureTypes = ['Corporation', 'Limited Liability Company', 'Professional Association', 'Sole Proprietorship', 'General Partnership', 'Limited Partnership', 'Non-Profit', 'Professional Corporation'];

    let legalStructures = legalStructureTypes.map((text) => <option value={text} key={text} style={styles.option}>{text}</option>);

    useEffect(() => {
        if (brokerInfoFetchedRef.current) {
            return;
        }
        brokerInfoFetchedRef.current = true;
        getBrokers();
    }, []);

    useEffect(() => {
        fetch(`${baseApiUrl}/v1/application/industries`)
            .then(result => result.json())
            .then(data => setIndustries(data))
    }, []);

    const getBrokers = async () => {
        const response = await fetch(`${baseApiUrl}/v1/broker/fetch?broker_id=${brokerId}&api-key=${apiKey}`, {
            method: 'GET',
        });
        const data = await response.json();
        if (data.success !== true) {
          setErrorMessage('Error retrieving Brokers');
        } else {
          setBrokerDictionary(data.response);
          if(Object.keys(data.response).length === 1) {
            let [id] = Object.keys(data.response);
            setBrokerCompanyId(id);
            setIsInternal(true);
          }
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <h1>Broker App</h1>
                {errorMessage ? (
                    <div className="alert alert-danger">
                    {errorMessage}
                    </div>
                ) : null}
                <div style={styles.formContainer}>
                { dealLink
                    ? (<><h2>Deal:</h2> <a href={dealLink} style={{color: '#00ffcf'}} >{ dealId }</a></>)
                    : (
                <form onSubmit={handleSubmit}>
                    {!isInternal ? <div style={styles.formGroup}>
                        <label htmlFor="brokerCompanyId" style={styles.label}>Broker:</label>
                        <select onChange={handleChange} style={styles.select} value={brokerCompanyId.toString()} name="brokerCompanyId">
                            <option value="" disabled style={styles.option}>Select</option>
                            {Object.entries(brokerDictionary).map(([key, value]) => (
                                <option value={key} key={key} style={styles.option}>{value}</option>
                            ))}
                        </select>
                    </div> : ''}
                    <div style={styles.formGroup}>
                        <label htmlFor="brokerAgentEmail" style={styles.label}>Broker Agent Email:</label>
                        <input type="text" name="brokerAgentEmail" id="brokerAgentEmail" style={styles.input} onChange={handleChange} value={brokerAgentEmail}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="brokerAgentPhone" style={styles.label}>Broker Agent Phone:</label>
                        <input type="text" name="brokerAgentPhone" id="brokerAgentPhone" style={styles.input} onChange={handleChange} value={brokerAgentPhone} maxLength="10" minLength="10" ></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="brokerAgentFirst" style={styles.label}>Broker Agent First Name:</label>
                        <input type="text" name="brokerAgentFirst" id="brokerAgentFirst" style={styles.input} onChange={handleChange} value={brokerAgentFirst}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="brokerAgentLast" style={styles.label}>Broker Agent Last Name:</label>
                        <input type="text" name="brokerAgentLast" id="brokerAgentLast" style={styles.input} onChange={handleChange} value={brokerAgentLast}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="timeInBusiness" style={styles.label}>Time In Business (months):</label>
                        <input type="text" name="timeInBusiness" id="timeInBusiness" style={styles.input} onChange={handleChange} value={timeInBusiness}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="grossAnnualSales" style={styles.label}>Gross Annual Sales:</label>
                        <input type="text" name="grossAnnualSales" id="grossAnnualSales" style={styles.input} onChange={handleChange} value={grossAnnualSales}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="amountRequested">Amount Requested:</label>
                        <div style={styles.currencyInputContainer}>
                            <span style={styles.currencySymbol}>$</span>
                            <input style={styles.currencyInput} type="text" id="amountRequested" name="amountRequested" onChange={handleChange} value={amountRequested}/>
                        </div>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="industry" style={styles.label}>Industry:</label>
                        <select onChange={handleChange} style={styles.select} value={industry} name="industry">
                            <option disabled value="" style={styles.option}>Select</option>
                            {industries.map((i) => (
                                <option value={i.value} key={i.value} style={styles.option}>{i.label}</option>))}
                        </select>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="businessName" style={styles.label}>Business Name:</label>
                        <input type="text" name="businessName" id="businessName" style={styles.input} onChange={handleChange} value={businessName}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="registeredLegalName" style={styles.label}>Registered Legal Name:</label>
                        <input type="text" name="registeredLegalName" id="registeredLegalName" style={styles.input} onChange={handleChange} value={registeredLegalName}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="ownerFirstname" style={styles.label}>Owner First Name:</label>
                        <input type="text" name="ownerFirstname" id="ownerFirstname" style={styles.input} onChange={handleChange} value={ownerFirstname}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="ownerLastname" style={styles.label}>Owner Last Name:</label>
                        <input type="text" name="ownerLastname" id="ownerLastname" style={styles.input} onChange={handleChange} value={ownerLastname}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="email" style={styles.label}>Email:</label>
                        <input type="text" name="email" id="email" style={styles.input} onChange={handleChange} value={email}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="businessPhone" style={styles.label}>Business Phone:</label>
                        <input type="text" name="businessPhone" id="businessPhone" style={styles.input} onChange={handleChange} value={businessPhone} maxLength="10" minLength="10"></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="mobilePhone" style={styles.label}>Mobile Phone:</label>
                        <input type="text" name="mobilePhone" id="mobilePhone" style={styles.input} value={mobilePhone} onChange={handleChange} maxLength="10" minLength="10"></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="businessAddress" style={styles.label}>Business Address:</label>
                        <input type="text" name="businessAddress" id="businessAddress" style={styles.input} onChange={handleChange} value={businessAddress}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="businessZip" style={styles.label}>Business Zip:</label>
                        <input type="text" name="businessZip" id="businessZip" style={styles.input} onChange={handleChange} value={businessZip}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="city" style={styles.label}>Business City:</label>
                        <input type="text" name="city" id="city" style={styles.input} onChange={handleChange} value={city}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="state" style={styles.label}>Business State:</label>
                        <input type="text" name="state" id="state" style={styles.input} onChange={handleChange} value={state}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="businessAddressIsHome" style={styles.label}>Business Address is same as Home Address?</label>
                        <select name="businessAddressIsHome" onChange={handleChange} value={businessAddressIsHome ? 'yes' : 'no'} style={styles.select} >
                            <option value="" disabled style={styles.option}>Select</option>
                            <option value='yes' key='yes'>Yes</option>
                            <option value='no' key='no'>No</option>
                        </select>
                    </div>
                    { !businessAddressIsHome
                        ?
                            (<>
                                <div style={styles.formGroup}>
                                    <label htmlFor="homeAddress" style={styles.label}>Home Address:</label>
                                    <input type="text" name="homeAddress" id="homeAddress" style={styles.input} onChange={handleChange} value={homeAddress}></input>
                                </div>
                                <div style={styles.formGroup}>
                                    <label htmlFor="homeZip" style={styles.label}>Home Zip:</label>
                                    <input type="text" name="homeZip" id="homeZip" style={styles.input} onChange={handleChange} value={homeZip}></input>
                                </div>
                                <div style={styles.formGroup}>
                                    <label htmlFor="homeCity" style={styles.label}>Home City:</label>
                                    <input type="text" name="homeCity" id="homeCity" style={styles.input} onChange={handleChange} value={homeCity}></input>
                                </div>
                                <div style={styles.formGroup}>
                                    <label htmlFor="homeState" style={styles.label}>Home State:</label>
                                    <input type="text" name="homeState" id="HomeState" style={styles.input} onChange={handleChange} value={homeState}></input>
                                </div>
                            </>)
                        : null
                    }
                    <div style={styles.formGroup}>
                        <label htmlFor="acceptsCreditCards" style={styles.label}>Does the Business Accept Credit Cards?</label>
                        <select name="acceptsCreditCards" onChange={handleChange} value={acceptsCreditCards ? 'yes': 'no'} style={styles.select} >
                            <option defaultValue disabled value="" style={styles.option}>Select</option>
                            <option value='yes' key='yes'>Yes</option>
                            <option value='no' key='no'>No</option>
                        </select>
                    </div>
                    { acceptsCreditCards
                        ?
                            (<>
                                <div style={styles.formGroup}>
                                    <label style={styles.label} htmlFor="avgMonthlySales">Average monthly credit card receipts in the last 3 months:</label>
                                    <div style={styles.currencyInputContainer}>
                                        <span style={styles.currencySymbol}>$</span>
                                        <input style={styles.currencyInput} type="text" id="avgMonthlySales" name="avgMonthlySales" onChange={handleChange} value={avgMonthlySales}/>
                                    </div>
                                </div>
                                <div style={styles.formGroup}>
                                    <label style={styles.label} htmlFor="lowestMonthlySales">Lowest monthly credit card receipts in the last 3 months:</label>
                                    <div style={styles.currencyInputContainer}>
                                        <span style={styles.currencySymbol}>$</span>
                                        <input style={styles.currencyInput} type="text" id="lowestMonthlySales" name="lowestMonthlySales" onChange={handleChange} value={lowestMonthlySales}/>
                                    </div>
                                </div>
                            </>)
                        : null
                    }
                    <div style={styles.formGroup}>
                        <label htmlFor="dateOfBirth" style={styles.label}>Date of Birth:</label>
                        <input type="text" name="dateOfBirth" id="dateOfBirth" placeholder="dd/mm/yyyy" style={styles.input} onChange={handleChange} value={dateOfBirth}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label} htmlFor="percentOwnership">Percent of Ownership:</label>
                        <div style={styles.percentInputContainer}>
                            <input style={styles.percentInput} type="text" id="percentOwnership" name="percentOwnership" onChange={handleChange} value={percentOwnership}/>
                            <span style={styles.suffix}>%</span>
                        </div>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="ssn" style={styles.label}>SSN:</label>
                        <input type="text" name="ssn" id="ssn" value={ssn} maxLength="9" minLength="9" placeholder="### ## ####" style={styles.input} onChange={handleChange}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="ein" style={styles.label}>Tax ID Number / EIN:</label>
                        <input type="text" name="ein" id="ein" value={ein} maxLength="9" minLength="9" placeholder="#########" style={styles.input} onChange={handleChange}></input>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="legalStructure" style={styles.label}>Legal Structure:</label>
                        <select onChange={handleChange} style={styles.select} value={legalStructure} name="legalStructure">
                            <option value="" disabled style={styles.option}>Select</option>
                            {legalStructures}
                        </select>
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="referenceId" style={styles.label}>Reference ID:</label>
                        <input type="text" name="referenceId" id="referenceId" value={referenceId} maxLength="256" placeholder="Reference ID" style={styles.input} onChange={handleChange}></input>

                    </div>
                    { !isSubmitting
                        ? (<button style={styles.button} onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'} onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'} type="submit" >Submit</button>)
                        : (<p style={styles.label}>...Submitting Application...</p>)
                    }
                </form>)
                }
                </div>
            </header>
        </div>
    );

}
